//获取code
const getCode = `/gateway/blade-system/oauth/code`;
//设备列表
const getEquipmentList = `/admin/huacong/control/list`;
//详情
const getEquipmentDetail = `/admin/huacong/control`;
//根据部门id获取对应人员
const getUserHouseInfoByUserCode = `/gateway/blade-system/userHouse/getUserHouseInfoByUserCode`;
//设备控制
const updateControlEquipment = `/admin/huacong/control`;
//获取房号
const getSpaceLinkList = `/gateway/hc-property/space/getSpaceLinkListHasLastStage`;
//获取code
const getOauthCode = `/gateway/blade-system/oauth/code`;
//查询部门信息表
const getSpaceCut = `/gateway/hc-property/housingAsset/getSpaceCut`;
export {
  getCode,
  getEquipmentList,
  getEquipmentDetail,
  getUserHouseInfoByUserCode,
  updateControlEquipment,
  getOauthCode,
  getSpaceLinkList,
  getSpaceCut
};
